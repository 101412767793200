<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <span class="card-title">Flags de Importação</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="4">
          <v-switch v-model="importConfiguration.active" :disabled="!editable" value label="Active" />
        </v-col>
        <v-col cols="4">
          <v-switch v-model="importConfiguration.released" :disabled="!editable" value label="Released" />
        </v-col>
        <v-col cols="4">
          <v-switch v-model="importConfiguration.realtime" :disabled="!editable" value label="Realtime" />
        </v-col>
        <btn-card-actions class="pa-0" :editable.sync="editable" :saving="saving" @save="saveManager" @cancel="cancel" />
      </v-row>
      <change-dialog :dialog.sync="changesDialog" :message.sync="changesMessage" @proceed="editFlagImport" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { MUTATION_EDIT_IMPORT_FLAGS } from '@/modules/accounts/graphql'

export default {
  components: {
    BtnCardActions: () => import('@/components/base/BtnCardActions'),
    ChangeDialog: () => import('@/components/base/ChangeDialog')
  },
  props: {
    accountId: {
      type: String,
      default: ''
    },
    importConfiguration: {
      type: Object,
      default: () => ({})
    },
    isDeploying: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    editable: false,
    changesDialog: false,
    changesMessage: '',
    saving: false
  }),
  methods: {
    async editFlagImport() {
      const variables = {
        id: this.accountId,
        active: this.importConfiguration.active,
        released: this.importConfiguration.released,
        realtime: this.importConfiguration.realtime
      }
      this.$setLogMessage(this.changesMessage)

      try {
        this.saving = true
        await this.$apollo.mutate({
          mutation: MUTATION_EDIT_IMPORT_FLAGS,
          variables
        })
        this.$alert({
          alert_message: 'Flags de importação atualizadas com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.cancel()
      } catch (err) {
        console.log(err)
        this.$alert({
          alert_message: 'Falha ao editar flags de importação',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.saving = false
    },
    cancel() {
      this.editable = false
      this.$emit('cancel')
    },
    saveManager() {
      if (this.isDeploying) return this.editFlagImport()
      this.changesDialog = true
    }
  }
}
</script>
